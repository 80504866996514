<script lang="ts" setup>
import { internalRolesWithoutGuest } from '~/authorization'

const { status } = useAuth()
const { isRole, canAccessPage } = useAuthorization()

const { task } = useQuery()
const { data: userTaskCounts } = task.countsAssignedToUser(!isRole('ext-storage-user', false))

const taskBadgeType = computed(() => {
  const taskCounts = userTaskCounts.value
  if (!taskCounts) {
    return 'info'
  }
  if (taskCounts.open.overdue > 0) {
    return 'error'
  }
  if (taskCounts.open.pending > 0) {
    return 'warning'
  }
  return 'info'
})

const isMobile = useIsMobileScreen()
</script>

<template>
  <div class="flex items-center gap-2 h-full">
    <div class="pl-6 border-gray-100 lg:h-full lg:border-r lg:pl-0 lg:w-[272px] lg:grid lg:place-content-center">
      <NuxtLink href="/">
        <TheLogo :display-small="isMobile" class="cursor-pointer" />
      </NuxtLink>
    </div>
    <div class="grow pr-6 flex items-center gap-2 justify-end">
      <template v-if="status === 'authenticated'">
        <CommandPaletteInput v-if="isRole(internalRolesWithoutGuest)" />
        <n-badge v-if="canAccessPage('/tasks')" :value="userTaskCounts?.open.total" :type="taskBadgeType" :max="20">
          <NuxtLink to="/tasks">
            <n-button>
              <template #icon>
                <Icon name="material-symbols:task-alt-rounded" class="text-hos-blue" />
              </template>
            </n-button>
          </NuxtLink>
        </n-badge>

        <n-divider vertical />

        <NuxtLink to="/auth/logout">
          <n-button icon-placement="right">
            <template #icon>
              <Icon name="material-symbols:logout-sharp" class="text-hos-blue" />
            </template>
          </n-button>
        </NuxtLink>
      </template>
      <NuxtLink v-else to="/auth/login">
        <n-button type="primary">
          <template #icon>
            <Icon name="material-symbols:login" />
          </template>
          Anmelden
        </n-button>
      </NuxtLink>
    </div>
  </div>
</template>
