<script setup lang="ts">
const route = useRoute()

const { status } = useAuth()
const isAuthenticated = computed(() => status.value === 'authenticated')

const isFooterExpanded = ref(false)

const isNavbarCollapsed = useIsNavbarCollapsed()

useExternalRoleSessionTimeout()
</script>

<template>
  <div class="relative h-screen">
    <n-layout position="absolute">
      <n-layout-header style="height: 64px" class="bg-white" bordered>
        <TheTopBar />
      </n-layout-header>

      <n-layout has-sider position="absolute" style="top: 64px">
        <n-layout-sider
          v-if="isAuthenticated"
          :native-scrollbar="false"
          collapse-mode="width"
          :collapsed-width="80"
          :collapsed="isNavbarCollapsed"
          show-trigger
          bordered
          class="hidden md:block"
          @collapse="isNavbarCollapsed = true"
          @expand="isNavbarCollapsed = false"
        >
          <TheNavbar
            :current-path="route.path"
            :is-collapsed="isNavbarCollapsed"
            class="h-full w-full"
          />
        </n-layout-sider>

        <n-layout-content>
          <div class="relative h-full grow pb-10 bg-gray-100 overflow-auto">
            <slot />
            <div v-if="isAuthenticated" class="fixed md:hidden bottom-0 w-full bg-white max-h-screen h-fit z-10">
              <div v-if="!isFooterExpanded && !isNavbarCollapsed" class="h-12 w-full flex items-center border-t" @click="isFooterExpanded = true">
                <Icon name="material-symbols:menu-rounded" size="42" class="ml-2" />
              </div>
              <div v-else class="h-full w-screen fixed bg-white top-0 z-50">
                <TheNavbar
                  :current-path="route.path"
                  class="MobileMenuHeight w-full overflow-y-auto"
                  @select="isFooterExpanded = false"
                />
                <div class="sticky bottom-0 h-[42px]" @click="isFooterExpanded = false">
                  <n-button>
                    <Icon name="material-symbols:cancel-outline" size="25" class="mr-2" />
                    Menü schließen
                  </n-button>
                </div>
              </div>
            </div>
          </div>
        </n-layout-content>
      </n-layout>
    </n-layout>
  </div>
</template>

<style scoped>
.MobileMenuHeight {
  height: calc(100% - 42px);
}
</style>
